<template>
  <div style="position:relative; height:100vh">
    <iframe :src="url_data" frameborder="0" allowfullscreen
      style="position:relative;top:0;left:0;width:100%;height:100%;"></iframe>
  </div>
</template>
<script>
import commonFunction from "../../mixin/commonFunction";
export default {
  name: "view_user_reports",
  mixins: [commonFunction],
  mounted() {
    document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
    this.report_param = {
      id:this.$route.params.id,
      userid:this.$route.params.userid
    }
    this.getSingleReport();
    document.body.classList.add('viewreporthidden');
  },
  data() {
    return {
      report_id: null,
      url_data: null,
      report_param: null
    };
  },
  methods:{
      getSingleReport()
 {
        var _this = this;
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/view-employee-reports/",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },         
          data: {request_data : this.encodeAPIRequest(_this.report_param)},
        };
        _this
          .axios(config)
          .then(({
            data
          }) => {
            var decodedJson = _this.decodeAPIResponse(data.data);
            _this.url_data = decodedJson.summary_report;            
          })
          .catch(({
            response
          }) => {

            _this.$router.push({
                    name: "404",
                  });
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              _this.$toast.error(response.data.error, {
                position: "top-right",
                duration: 5000,
              });
             /*_this.$toast.error("Please view a valid report.", {
                    position: "top-right",
                    duration: 5000,
                  });*/
                  
            console.log(response);
          });
      },
  }
};
</script>